import sun from "../../images/sun.png"
import moon from "../../images/moon.png"
import Toggle from "../components/Toggle"
import React from "react"
import { useColorMode } from "theme-ui"

const DarkModeSwitch = () => {
  const [mode, setMode] = useColorMode()

  const handleChange = () => {
    setMode(mode === "default" ? "dark" : "default")
  }

  return (
    <Toggle
      icons={{
        checked: (
          <img
            src={moon}
            width="16"
            height="16"
            role="presentation"
            style={{ pointerEvents: "none" }}
          />
        ),
        unchecked: (
          <img
            src={sun}
            width="16"
            height="16"
            role="presentation"
            style={{ pointerEvents: "none" }}
          />
        ),
      }}
      checked={mode === "dark"}
      onChange={handleChange}
    />
  )
}

export default DarkModeSwitch
