/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { Flex, Image, jsx, Box, MenuButton } from "theme-ui"
import Link from "gatsby-link"
import Menu from "../components/Menu"
import Logo from "../../images/logo.png"
import { alpha } from "@theme-ui/color"
import DarkModeSwitch from "./DarkModeSwitch"

interface StyledDesktopLinkProps {
  children: React.ReactNode
  to: string
  partiallyActive?: boolean
}

const StyledDesktopLink = (props: StyledDesktopLinkProps) => {
  const { children, to } = props
  return (
    <Link
      to={to}
      activeClassName="active"
      sx={{
        padding: 3,
        paddingBottom: 2,
        color: "accent",
        textDecoration: "none",
        fontWeight: "bold",
        "&.active": {
          color: "secondary",
          borderBottomColor: "secondary",
          borderBottomStyle: "solid",
          borderBottomWidth: "2px",
          marginBottom: "-2px",
        },
        "&:hover": { color: "lightSecondary" },
      }}
      partiallyActive={props.partiallyActive}
    >
      {children}
    </Link>
  )
}

const Navbar = (): JSX.Element => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [solutionsMenuOpen, setSolutionsMenuOpen] = useState(false)
  const [shrinkHeight, setShrinkHeight] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  useEffect(() => {
    // Todo: test performance and throttle
    const onScroll = e => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        setShrinkHeight(true)
      } else {
        setShrinkHeight(false)
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  })

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        zIndex: 1,
        width: "100%",
        bg: shrinkHeight ? "background" : alpha("background", 0.8),
        boxShadow: shrinkHeight ? "0 2px 5px rgba(0,0,0,.1)" : "none",
        transition: "all 0.3s",
        backdropFilter: "blur(8px)",
      }}
      color="accent"
    >
      <Box
        as="nav"
        sx={{
          maxWidth: "1400px",
          margin: "auto",
        }}
      >
        <Flex
          px={3}
          pt={1}
          sx={{
            justifyContent: "space-between",
            alignItems: ["center", "flex-end"],
          }}
        >
          <Link to="/">
            <Image
              src={Logo}
              sx={{
                width: ["45px", shrinkHeight ? "50px" : "80px"],
                height: ["45px", shrinkHeight ? "50px" : "80px"],
                transition: "all 0.3s",
              }}
              alt="logo"
            />
          </Link>
          <Flex sx={{ display: ["none", "flex"], alignItems: "center" }}>
            <StyledDesktopLink to="/">Home</StyledDesktopLink>
            <StyledDesktopLink to="/products">Buy</StyledDesktopLink>
            <Flex
              onMouseEnter={() => setSolutionsMenuOpen(true)}
              onMouseLeave={() => setSolutionsMenuOpen(false)}
              sx={{ position: "relative", "&:hover": { color: "secondary" } }}
            >
              <StyledDesktopLink to="/solutions" partiallyActive={true}>
                Solutions
              </StyledDesktopLink>
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  minWidth: "250px",
                  left: "0%",
                }}
              >
                <Menu
                  open={solutionsMenuOpen}
                  links={[
                    { to: "/solutions", text: "Home & Home Office" },
                    { to: "/solutions/office", text: "Office" },
                    { to: "/solutions/outdoors", text: "Outdoors" },
                  ]}
                />
              </Box>
            </Flex>
            <StyledDesktopLink to="/#what-we-do">What We Do</StyledDesktopLink>
            <StyledDesktopLink to="/team">About Us</StyledDesktopLink>
            <StyledDesktopLink to="/blog">Blog</StyledDesktopLink>
            <StyledDesktopLink to="/faq">FAQ</StyledDesktopLink>
            <StyledDesktopLink to="/contactUs">Contact</StyledDesktopLink>
            <Box mx={3}>
              <DarkModeSwitch />
            </Box>
          </Flex>
          <Flex sx={{ alignItems: "center", display: ["flex", "none"] }}>
            <Box m={3} mt={2} mb={0}>
              <DarkModeSwitch />
            </Box>
            <MenuButton aria-label="Toggle Menu" onClick={toggleMobileMenu} />
          </Flex>
        </Flex>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            minWidth: "250px",
            transform: "translateX(-50%)",
          }}
        >
          <Menu
            open={mobileMenuOpen}
            links={[
              { to: "/", text: "Home" },
              { to: "/products", text: "Buy" },
              { to: "/solutions", text: "Solutions" },
              { to: "/workshop", text: "What We Do" },
              { to: "/team", text: "About Us" },
              { to: "/blog", text: "Blog" },
              { to: "/faq", text: "FAQ" },
              { to: "/contactUs", text: "Contact" },
            ]}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Navbar
