/** @jsx jsx */
import React from "react"
import AnimateHeight from "react-animate-height"
import { Flex, jsx } from "theme-ui"
import Link from "gatsby-link"
import { alpha } from "@theme-ui/color"

interface MenuLinkProps {
  children: React.ReactNode
  to: string
}

const MenuLink = (props: MenuLinkProps) => {
  const { children, to } = props
  return (
    <Link
      to={to}
      activeClassName="active"
      sx={{
        padding: 3,
        textDecoration: "none",
        fontWeight: "bold",
        borderBottom: "1px solid #f0f0f0",
        color: "accent",
        "&.active": {
          color: "secondary",
        },
        "&:hover": { color: "lightSecondary" },
      }}
    >
      {children}
    </Link>
  )
}

interface LinkInterface {
  text: string
  to: string
}

interface MenuProps {
  open: boolean
  links: LinkInterface[]
}

const Menu = (props: MenuProps) => {
  return (
    <AnimateHeight duration={500} height={props.open ? "auto" : 0}>
      <Flex
        sx={{
          flexDirection: "column",
          borderTopWidth: "3px",
          borderTopStyle: "solid",
          borderTopColor: "secondary",
          boxShadow: "0 2px 5px rgba(0,0,0,.1)",
          bg: alpha("background", 0.9),
        }}
      >
        {props.links.map((link, index) => (
          <MenuLink key={index} to={link.to}>
            {link.text}
          </MenuLink>
        ))}
      </Flex>
    </AnimateHeight>
  )
}

export default Menu
