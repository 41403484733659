/** @jsx jsx */
import React from "react"
import { Heading, Image, Text, Flex, Box, Button, jsx } from "theme-ui"

import Instagram from "../../images/instagram.svg"
import Facebook from "../../images/facebook.svg"
import { Link } from "gatsby"

const Footer = (): JSX.Element => {
  return (
    <footer>
      <Box bg="footerColor" color="white" p={4} sx={{ textAlign: "center" }}>
        <Heading sx={{ fontSize: 5 }} mb={3}>
          Do you have any questions?
        </Heading>
        <Box mb={3}>
          <Text variant="default" color="white">
            Phone: 99001-70470 / 98457-62765
          </Text>
        </Box>
        <Link to="/contactUs">
          <Button
            sx={{
              border: "1px solid white",
              borderRadius: "0",
            }}
            px={5}
            py={1}
            bg="transparent"
            color="white"
          >
            Send Message
          </Button>
        </Link>
      </Box>
      <Flex
        bg="muted"
        p={2}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box mr={3}>
          <Link to="/privacy">
            <a
              sx={{
                color: "white",
                fontSize: 2,
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Privacy Policy
            </a>
          </Link>
        </Box>
        <a
          target="_blank"
          href="https://www.facebook.com/YourGreenCanvas/"
          rel="noreferrer"
        >
          <Image
            src={Facebook}
            alt="facebook"
            sx={{ width: "2rem", height: "2rem" }}
            mr={3}
          />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/yourgreencanvasbangalore/"
          rel="noreferrer"
        >
          <Image
            src={Instagram}
            alt="instagram"
            sx={{ width: "2rem", height: "2rem" }}
            mr={4}
          />
        </a>
      </Flex>
    </footer>
  )
}

export default Footer
