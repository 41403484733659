import React from "react"
import { Box, Container, useThemeUI } from "theme-ui"
import { Global, css } from "@emotion/core"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

// css for third party libraries
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface LayoutProps {
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { children } = props
  const { theme } = useThemeUI()

  return (
    <Box>
      <Navbar />
      <Container>{children}</Container>
      <Footer />
      <Global
        styles={css`
          * {
            transition: background 1s;
          }
          html,
          body {
            max-width: 100%;
            overflow-x: hidden;
          }
          .slick-dots li {
            width: auto;
            height: auto;
            margin: 0 5px;
          }
          .slick-dots li button {
            width: 40px;
            height: 2px;
            padding: 0;
            background-color: ${theme.colors.accent};
            transition: all 0.3s;
            transform-origin: 0 100%;
          }
          .slick-dots li button::before {
            content: none;
          }
          .slick-dots .slick-active button {
            transform: scaleY(1.4);
            background-color: ${theme.colors.primary};
          }
          html {
            font-size: 19px;
          }
          a {
            text-decoration: none;
          }
          @media (max-width: 768px) {
            html {
              font-size: 16px;
            }
          }
        `}
      />
    </Box>
  )
}

export default Layout
